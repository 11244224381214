import back from "../../assets/images/back.png";
import React, {useContext} from "react";
import {Link} from "react-router-dom";
import {SidebarContext} from "../../contexts/SidebarContext";
import {remove} from "../../utils/storage";
import {SAVE_PROPS} from "../../utils/saveProps";

function BackToDirectors(props) {

    const {currentDirectorName, unsetDirectorPage} = useContext(SidebarContext);

    return (
        <>
            <div id="backToDirectorsBtn" className="back-btn">
                <Link onClick={() => {remove(SAVE_PROPS.LAST_DIRECTOR_SLIDE_INDEX); remove(SAVE_PROPS.LAST_MOBILE_DIRECTOR_SLIDE_INDEX); unsetDirectorPage()}} to={'/directors'} replace={true}>
                    <img src={back} alt="" />
                </Link>
            </div>
            {currentDirectorName && (
                <div className="single-director-name item filter font-text">{currentDirectorName}</div>
            )}
        </>
    )
}

export default BackToDirectors;
