
export const save = (property, value) => {
    localStorage.setItem(property, value);
    console.warn(`Saved item with property ${property} and value ${value}`);
}

export const get = (property) => {
    const value = localStorage.getItem(property);

    console.warn(`Get item with value: ${value}`);

    return (value ? value : undefined);
}

export const getInteger = (property) => {
    const value = localStorage.getItem(property);

    console.warn(`Get item with value: ${value}`);

    const parsedValue = parseInt(value);

    return (!isNaN(parsedValue) ? parsedValue : 0);
}

export const remove = (property) => {
    if (hasItem(property)) {
        localStorage.removeItem(property);
        console.warn(`Removed item with property ${property}`);
    }
}

export const hasItem = (property) => {
    return !!get(property);
}
