import { useState, createContext } from "react"
import LoadingOverlay from "../components/LoadingOverlay"

// eslint-disable-next-line
export const LoadingOverlayContext = createContext({})

const DELAY_TO_ACTIVATION_MS = 20;
const MINIMUM_PRESENCE_TIME_MS = 1000;
let timeoutInstance

export const LoadingOverlayProvider = ({ children }) => {
    const [shown, setShown] = useState(false)

    const hide = () => {
        if (timeoutInstance) {
            clearTimeout(timeoutInstance)
        }

        setTimeout(() => {
            setShown(false)
        }, MINIMUM_PRESENCE_TIME_MS)
    }

    const show = () => {
        if (timeoutInstance) {
            clearTimeout(timeoutInstance)
        }

        timeoutInstance = setTimeout(() => {
            setShown(true)
        }, DELAY_TO_ACTIVATION_MS)
    }

    return (
        <LoadingOverlayContext.Provider
            value={{
                shown,
                show,
                hide
            }}
        >
            <LoadingOverlay active={shown} />
            {children}
        </LoadingOverlayContext.Provider>
    )
}
