import { useState, createContext } from "react"

export const SidebarContext = createContext({})

export const SidebarProvider = ({ children }) => {
    const [isDirectorPage, setIsDirectorPage] = useState(false);
    const [currentDirectorName, setCurrentDirectorName] = useState(undefined);

    const setDirectorName = (directorName) => {
        setCurrentDirectorName(directorName);
    }

    const unsetDirectorName = () => {
        setCurrentDirectorName(undefined);
    }

    const unsetDirectorPage = () => {
        setIsDirectorPage(false);
    }

    const setDirectorPage = () => {
        setIsDirectorPage(true);
    }

    return (
        <SidebarContext.Provider
            value={{
                isDirectorPage,
                unsetDirectorPage,
                setDirectorPage,
                currentDirectorName,
                setDirectorName,
                unsetDirectorName
            }}
        >
            {children}
        </SidebarContext.Provider>
    )
}
