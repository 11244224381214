import React, { useEffect } from "react"
import NProgress from "nprogress"
import CircularLoader from "../CircularLoader";

const LoadingOverlay = ({ active }) => {
    useEffect(() => {
        NProgress.start()

        return () => {
            NProgress.done()
        }
    }, [])

    return (
        <>
            <div className={"loading-overlay"}
                style={{
                    background: "rgba(0, 0, 0, 0.75)",
                    transition: "opacity 0.25s",
                    opacity: `${active ? "1" : "0"}`,
                    pointerEvents: active ? "all" : "none"
                }}
            >
                {/*active && <CircularProgress size={64} disableShrink thickness={3} />*/}
                {active && (
                    <div>
                        <CircularLoader
                            primaryColor="#FFF"
                            secondaryColor="transparent"
                            diameter="100px"
                            primaryWidth="5px"
                            secondaryWidth="5px"/>
                    </div>
                )}
            </div>
        </>
    )
}

export default LoadingOverlay
