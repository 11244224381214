import React from "react";
import $ from 'jquery';

const CURSOR_SPEED_MS = 10;

export const CustomCursor =() => {
    
    
    React.useEffect(() => {

        var mouseX = 0, mouseY = 0;
        var xp = 0, yp = 0;
         
        $(document).mousemove(function(e){
          mouseX = e.pageX - 10;
          mouseY = e.pageY - 10; 
        });
          
        setInterval(function(){
          xp += ((mouseX - xp)/6);
          yp += ((mouseY - yp)/6);
          $("#cursor").css({left: xp +'px', top: yp +'px'});
        }, CURSOR_SPEED_MS);
      
    
        
    }, []);

    return (
        <div className="div">
        <span id="cursor">
        </span>
        </div>
   
    )
};