import React from "react";
import './assets/scss/main.css';
import Sidebar from "./components/Sidebar";
import Navbar from "./components/Navbar";
import {BrowserRouter as Router} from 'react-router-dom';
import { CustomCursor } from './components/CustomCursor';
const AnimateRoutes = React.lazy(() => import('./components/AnimatedRoutes'))
function App() {
  
    return (
      <Router>
              <CustomCursor />
              <section className='main-website'>
                  <Sidebar />
                  <Navbar />
                  <AnimateRoutes />
              </section>
      </Router>
  );

}

export default App;
