import React, { useEffect, useRef } from 'react';
import { Link } from "react-router-dom";
import $ from 'jquery';
import {gsap, Power3} from "gsap";
import {ScrollTrigger} from 'gsap/ScrollTrigger';

function Navbar() {
    return(
        <nav className="menu menu-closed">
            <div className="items">
                <div className="item font-text" ><Link to='/home'>Home</Link></div>
                <div className="item font-text" ><Link to='/work'>Works</Link></div>
                <div className="item font-text" ><Link to='/directors'>Directors</Link></div>
                <div className="item font-text" ><Link to='/contact'>Contact</Link></div>
                {/* <div className="item" ><Link to='/about'>About</Link></div> */}

            </div>
        </nav>
    );
}

export default Navbar;