export const MENU_CATEGORIES = [
    {
        ID: 5,
        NAME: "ADVERTISING"
    },
    {
        ID: 6,
        NAME: "FASHION"
    },
    {
        ID: 9,
        NAME: "NARRATIVE"
    },
    {
        ID: 4,
        NAME: "TBF-POST"
    }
];
