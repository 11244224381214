import React, {useContext, useEffect} from 'react';
import logo from '../assets/images/logos/logo.gif'
import vimeo from '../assets/images/vimeo.png'
import instagram from '../assets/images/instagram.png'
import spotify from '../assets/images/spotify.png'
import {Link} from "react-router-dom";
import CloseIcon from "./CloseIcon";
import $ from 'jquery';
import {PROPS} from "../utils/props";
import {MENU_CATEGORIES} from "../data/headerMenuData";
import {SidebarContext} from "../contexts/SidebarContext";
import BackToHome from "./BackToDirectors";
import BackToDirectors from "./BackToDirectors";
import {remove} from "../utils/storage";
import {SAVE_PROPS} from "../utils/saveProps";
function Sidebar() {

    const {isDirectorPage} = useContext(SidebarContext);

    function handleCloseMenu() {
        $('.socials-menu.mobile').fadeOut(120);
        $('.menu-bb').slideUp();
        setTimeout(() => {
            $('body').removeClass('menu-open');
        }, 200);
    }

    useEffect(() => {
        $('.socials-menu.mobile').hide();

        $('.mobile-hamburgher').on("click", function(){
            $('.menu-bb').slideDown(400, function() {
                $('.socials-menu.mobile').fadeIn(180);
            });
            setTimeout(() => {
                $('body').addClass('menu-open');
            }, 160);

            setTimeout(() => {
                for (let index = 1; index < $('.menu-bb').find('video').length; index++) {
                    $('.menu-bb').find('video').eq(index).remove()
                }
            }, 1000);

        })
    })
    
    return(
        <aside>
            <Link onClick={()=>{remove(SAVE_PROPS.LAST_HOME_URL); window.location.href = '/home';}} to='/home'><img src={logo} alt="" className="logo"/></Link>
            <div className="mobile-menu-close" onClick={handleCloseMenu}>
                <CloseIcon width={20}/>
            </div>
            <div className="menu-bb">
                <div className="items">
                    {isDirectorPage ?
                    <>
                        <BackToDirectors/>
                    </>
                        :
                        <>
                            <Link onClick={()=>{window.location.href = '/directors';}} to='/directors'><div className="item font-text" >DIRECTORS</div></Link>
                            {MENU_CATEGORIES.map(entry => (
                                entry.ID === 9 ?
                                    <Link onClick={()=>{window.location.href = '/narrative';}} to='/narrative'><div className="item font-text" >{entry.NAME}</div></Link>
                                    :
                                    <Link to='/home' state={{from: entry.ID}}><div className="item filter font-text" data-tag={entry.ID}>{entry.NAME}</div></Link>
                            ))}
                        </>
                    }
                </div>
                <div className="contact-menu">
                <Link  onClick={()=>{window.location.href = '/contact';}} to='/contact'><div className="item font-text">CONTACT</div></Link>
                </div>
                <div className="socials-menu mobile">
                    <p><a href={PROPS.SOCIAL_VIMEO_URL} target='_blank'><img style={{width: '30px'}} src={vimeo} /></a></p>
                    <p><a href={PROPS.SOCIAL_INSTAGRAM_URL} target='_blank'><img style={{width: '30px'}} src={instagram} /></a></p>
                    <p><a href={PROPS.SOCIAL_SPOTIFY_URL} target={'_blank'}><img style={{width: '30px'}} src={spotify} /></a></p>
                </div>
            </div>
            <div className="socials mobile">
                <div className="mobile-hamburgher">
                    <div className="item menu-btn font-text">
                        <div className='line'></div>
                        <div className='line'></div>
                        <div className='line'></div>
                    </div>
                </div>
            </div>
            <div className="socials desktop">
                <p><a href={PROPS.SOCIAL_VIMEO_URL} target='_blank'><img style={{width: '30px'}} src={vimeo} /></a></p>
                <p><a href={PROPS.SOCIAL_INSTAGRAM_URL} target='_blank'><img style={{width: '30px'}} src={instagram} /></a></p>
                <p><a href={PROPS.SOCIAL_SPOTIFY_URL} target={'_blank'}><img style={{width: '30px'}} src={spotify} /></a></p>
            </div>
        </aside>
    );
}

export default Sidebar;